export default function HomeIcon({ size = 24 }) {
	const height = size;
	const width = (size * 12) / 11;
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 22" fill="none">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.65805 12.239V21.311H20.272L3.65814 21.311V21.3109V18.1958V12.2389L3.65805 12.239ZM1.70743 13.5444C1.30885 13.5443 0.910273 13.3923 0.606164 13.0882C-0.00210155 12.4799 -0.00210152 11.4937 0.606164 10.8854L10.8856 0.606051C11.1759 0.315733 11.5523 0.16398 11.9326 0.150793C11.5522 0.163959 11.1758 0.315712 10.8855 0.606055L0.60607 10.8854C-0.00219629 11.4937 -0.00219629 12.4799 0.606069 13.0882C0.910202 13.3923 1.30882 13.5444 1.70743 13.5444ZM22.2664 13.5445C21.8679 13.5445 21.4693 13.3924 21.1652 13.0883L20.2721 12.1952V12.1953L21.1651 13.0883C21.4692 13.3925 21.8678 13.5445 22.2664 13.5445ZM20.4219 21.4608V18.046L20.4218 12.557L21.0591 13.1943C21.7259 13.8611 22.807 13.8611 23.4738 13.1943C24.1406 12.5275 24.1406 11.4464 23.4738 10.7796L13.1944 0.500235C13.1076 0.413417 13.0136 0.337806 12.9143 0.27348C12.2527 -0.155308 11.3595 -0.0799491 10.7795 0.50008L0.500095 10.7795C-0.166698 11.4463 -0.166698 12.5273 0.500095 13.1941C1.16689 13.8609 2.24798 13.8609 2.91477 13.1941L3.50818 12.6007V21.4608H20.4219ZM6.92304 18.046V9.18587L11.9869 4.12205L17.007 9.14215V18.046H6.92304ZM11.9869 3.91015L17.1568 9.08007V18.1958L17.1569 18.1958V9.08007L11.987 3.9101L11.9869 3.91015Z"
				fill="#BB3D3D"
			/>
		</svg>
	);
}
