const initialState = {
	id: null,
	gamername: null,
	email: null,
	games: [],
	isLoading: true
};

const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case "ADD_USER":
			if (action.payload) {
				return {
					...state,
					...action.payload
				};
			}
			return {
				...state
			};
		case "SET_GAMERNAME":
			if (action.payload) {
				return {
					...state,
					gamername: action.payload
				};
			}
			return {
				...state
			};
		case "REMOVE_USER":
			return {
				...initialState,
				isLoading: false
			};
		case "SET_DONE_LOADING":
			return {
				...state,
				isLoading: false
			};
		case "ADD_GAME":
			return {
				...state,
				games: [...state.games, action.payload]
			};
		default:
			return state;
	}
};

export default userReducer;
