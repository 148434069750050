import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Support from "./routes/support";
import { connect } from "react-redux";
import { supabase } from "./lib/supabase";
import PrivacyPolicy from "./pages/privacy-policy";
import JoinGame from "./pages/join-game.js";

function App({}) {
	return (
		<div className="App">
			<BrowserRouter>
				<Routes>
					<Route path="/" exact element={<JoinGame />} />
					<Route path="/support" exact element={<Support />} />
					<Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
				</Routes>
			</BrowserRouter>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(App);
