import { Link } from "react-router-dom";
import { Box, Button, keyframes, styled, useMediaQuery } from "@mui/material";
import { colors } from "../styles/styles";
import { Logo, ProfileIcon } from "./icons";
import { RowBox } from "./boxes";
import { Text } from "./text";
import { useState } from "react";
import { connect } from "react-redux";

const NavBox = styled(Box)({
	background: colors.redMain,
	display: "flex",
	alignItems: "center",
	flexDirection: "row",
	height: "59px",
	justifyContent: "space-between",
	width: "100%",
	position: "fixed",
	zIndex: 4
});

const NavBoxMobile = styled(NavBox)({
	height: 50
});

const NavMenuBox = styled(Box)({
	position: "fixed",
	top: 50,
	right: 0,
	zIndex: 4,
	backgroundColor: colors.redMain,
	padding: "20px 28px",
	display: "flex",
	flexDirection: "column",
	gap: 16,
	alignItems: "flex-start"
});

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const NavBoxInGame = styled(NavBox)(({ fullscreen }) => ({
	position: "absolute",
	animation: `${fadeOut} 3s ease-in-out`,
	opacity: fullscreen ? 0 : 1,
	transition: "opacity .4s ease-in-out",
	"&:hover": {
		opacity: 1
	},
	zIndex: 2,
	top: 0
}));

const WhiteButton = styled(Button)({
	padding: "5px 15px",
	borderRadius: 25,
	background: colors.whiteMain,
	textTransform: "none",
	"&:hover": {
		background: colors.whiteMain
	},
	"&:active": {
		background: colors.grayMain
	}
});

function NavBar({ user, page, gameActive, fullScreen, setFullScreen, onExit, backArrow, onBackClick }) {
	const isMobile = useMediaQuery("(max-width: 950px)");
	const [openMenu, setOpenMenu] = useState(false);
	const [openProfile, setOpenProfile] = useState(false);

	if (isMobile) {
		if (backArrow) {
			return (
				<NavBoxMobile>
					<RowBox>
						<Box style={{ width: 25 }} />
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="19"
							height="16"
							viewBox="0 0 19 16"
							fill="none"
							style={{ cursor: "pointer" }}
							onClick={onBackClick}
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M10.4035 2.40353L7.99999 0L0 7.99999L7.99999 16L10.4035 13.5964L6.50665 9.69957H18.2661V6.30046H6.5066L10.4035 2.40353Z"
								fill="white"
							/>
						</svg>
					</RowBox>
					<Logo />
					<Box style={{ width: 44 }} />
				</NavBoxMobile>
			);
		}
		return (
			<>
				<NavBoxMobile>
					<RowBox>
						<Box style={{ width: 25 }} />
						<Logo />
					</RowBox>
					<RowBox>
						<RowBox width={24} style={{ cursor: "pointer" }} onClick={() => setOpenMenu(!openMenu)}>
							<svg xmlns="http://www.w3.org/2000/svg" width="4" height="18" viewBox="0 0 4 18" fill="none">
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M4.0001 2.34783C4.0001 3.36835 3.1728 4.19565 2.15227 4.19565C1.13174 4.19565 0.304443 3.36835 0.304443 2.34783C0.304443 1.3273 1.13174 0.5 2.15227 0.5C3.1728 0.5 4.0001 1.3273 4.0001 2.34783ZM4.0001 15.6522C4.0001 16.6727 3.1728 17.5 2.15227 17.5C1.13174 17.5 0.304443 16.6727 0.304443 15.6522C0.304443 14.6316 1.13174 13.8043 2.15227 13.8043C3.1728 13.8043 4.0001 14.6316 4.0001 15.6522ZM2.15227 10.8478C3.1728 10.8478 4.0001 10.0205 4.0001 8.99999C4.0001 7.97947 3.1728 7.15217 2.15227 7.15217C1.13174 7.15217 0.304443 7.97947 0.304443 8.99999C0.304443 10.0205 1.13174 10.8478 2.15227 10.8478Z"
									fill="white"
								/>
							</svg>
						</RowBox>
						<Box style={{ width: 15 }} />
					</RowBox>
				</NavBoxMobile>
				{openMenu ? (
					<NavMenuBox>
						<Link to="/" style={{ textDecoration: "none" }}>
							<Text weight={600} color={colors.whiteMain} opacity={page === "store" ? 1 : 0.5} style={{ cursor: "pointer" }}>
								Home
							</Text>
						</Link>
						<Link to="/support" style={{ textDecoration: "none" }}>
							<Text weight={600} color={colors.whiteMain} opacity={page === "contact" ? 1 : 0.5} style={{ cursor: "pointer" }}>
								Support
							</Text>
						</Link>
					</NavMenuBox>
				) : null}
			</>
		);
	}

	if (backArrow) {
		return (
			<NavBox>
				<RowBox>
					<Box style={{ width: 25 }} />
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="19"
						height="16"
						viewBox="0 0 19 16"
						fill="none"
						style={{ cursor: "pointer" }}
						onClick={onBackClick}
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M10.4035 2.40353L7.99999 0L0 7.99999L7.99999 16L10.4035 13.5964L6.50665 9.69957H18.2661V6.30046H6.5066L10.4035 2.40353Z"
							fill="white"
						/>
					</svg>
				</RowBox>
				<Logo />
				<Box style={{ width: 44 }} />
			</NavBox>
		);
	}

	return (
		<NavBox>
			<RowBox width={300} justify={"flex-start"} style={{ minWidth: 300 }}>
				<Box style={{ width: 25 }} />
				<Logo />
			</RowBox>
			<RowBox gap={25} width={350} style={{ minWidth: 350 }}>
				<Link to="/" style={{ textDecoration: "none" }}>
					<Text color={colors.whiteMain} weight={500} opacity={page === "about" ? 1 : 0.5} style={{ cursor: "pointer" }}>
						Home
					</Text>
				</Link>
				<Link to="/support" style={{ textDecoration: "none" }}>
					<Text color={colors.whiteMain} weight={500} opacity={page === "contact" ? 1 : 0.5} style={{ cursor: "pointer" }}>
						Support
					</Text>
				</Link>
			</RowBox>
			<RowBox gap={25} width={300} justify={"flex-end"} style={{ minWidth: 300 }}></RowBox>
		</NavBox>
	);
}

const mapStateToProps = (state) => {
	return {
		user: state.user
	};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
