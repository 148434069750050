import { ColumnBox, RowBox, HorizontalLine } from "./boxes";
import { LogoVertical } from "./icons";
import { Text } from "./text";
import { Box } from "@mui/material";
import { colors } from "../styles/styles";

export default function Footer({ includeSocials }) {
	return (
		<ColumnBox>
			<HorizontalLine />
			<Box style={{ height: 25 }} />
			<ColumnBox gap={15}>
				<LogoVertical />
				{includeSocials ? (
					<>
						<Text lineheight={1.375}>Follow us on social media</Text>
						<RowBox gap={25}>
							<Box
								style={{
									backgroundColor: "#5865F2",
									width: 50,
									height: 50,
									borderRadius: 25,
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									cursor: "pointer"
								}}
								onClick={() => window.open("https://discord.gg/GXzak4NYT4")}
							>
								<img src={"./discord.svg"} alt={"discord"} style={{ width: 30 }} />
							</Box>
							<img
								src={"./instagram.png"}
								alt={"instagram"}
								style={{
									cursor: "pointer",
									width: 50,
									borderRadius: 25,
									overflow: "hidden"
								}}
								onClick={() =>
									window.open("https://www.instagram.com/maddergames?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw%3D%3D")
								}
							/>
							<img
								src={"./linkedin.png"}
								alt={"linkedin"}
								style={{
									cursor: "pointer",
									width: 50,
									borderRadius: 25,
									overflow: "hidden"
								}}
								onClick={() =>
									window.open(
										"https://www.linkedin.com/posts/madder-games_in-the-last-month-we-have-developed-a-gaming-activity-7169024458123362309-adrY/?utm_source=share&utm_medium=member_desktop"
									)
								}
							/>
						</RowBox>
					</>
				) : null}
				<RowBox gap={10}>
					<Text size={14}>© 2024 Madder. All rights reserved.</Text>
					<Text
						size={14}
						weight={700}
						color={colors.redMain}
						onClick={() => {
							window.open("/privacy-policy");
						}}
						style={{ cursor: "pointer" }}
					>
						Privacy Policy
					</Text>
				</RowBox>
			</ColumnBox>
			<Box style={{ height: 25 }} />
		</ColumnBox>
	);
}
