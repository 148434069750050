export default function LeftArrowIcon({ size = 24 }) {
	const height = size;
	const width = (size * 52) / 60;
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 52 60" fill="none">
			<path
				d="M2.4262 34.2354C-0.80863 32.293 -0.808631 27.6044 2.4262 25.6621L43.9632 0.721431C47.2958 -1.27961 51.5371 1.12085 51.5371 5.00805L51.5371 54.8894C51.5371 58.7766 47.2958 61.1771 43.9632 59.176L2.4262 34.2354Z"
				fill="#BB3D3D"
			/>
		</svg>
	);
}
