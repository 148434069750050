import { Box, useMediaQuery } from "@mui/material";
import { ColumnBox, ConstraintBox, HorizontalLine } from "../components/boxes";
import { Text } from "../components/text";
import { colors } from "../styles/styles";
import NavBar from "../components/nav-bar";
import Footer from "../components/footer";

export default function Support() {
	const isMobile = useMediaQuery("(max-width: 600px)");

	return (
		<>
			<NavBar />
			<Box style={{ height: 50, padding: 20 }} />
			<ColumnBox gap={25}>
				<Text size={isMobile ? 28 : 40} weight={700}>
					Support
				</Text>
				<ConstraintBox width={isMobile ? 360 : 550}>
					<Text align={"center"}>If you have questions or concerns, please contact our support team at:</Text>
				</ConstraintBox>
				<Text
					color={colors.redMain}
					weight={700}
					style={{ cursor: "pointer" }}
					onClick={() => (window.location.href = "mailto:support@maddergames.com")}
				>
					support@maddergames.com
				</Text>
				<HorizontalLine width={isMobile ? 350 : 500} />
				<Text size={isMobile ? 28 : 40} weight={700}>
					Developers
				</Text>
				<ConstraintBox width={isMobile ? 360 : 516}>
					<Text align={"center"}>If you are interested in putting your game on maddergames.com, please contact us at:</Text>
				</ConstraintBox>
				<Text
					color={colors.redMain}
					weight={700}
					style={{ cursor: "pointer" }}
					onClick={() => (window.location.href = "mailto:developers@maddergames.com")}
				>
					developers@maddergames.com
				</Text>
			</ColumnBox>
			<Box style={{ flex: 1, padding: 20 }} />
			<Footer />
		</>
	);
}
