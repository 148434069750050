export default function TriangleIcon({ size = 53 }) {
	const height = size;
	const width = (size * 60) / 53;
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 60 53" fill="none">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M34.2867 3.13921C32.3444 -0.0956184 27.6558 -0.0956177 25.7134 3.13921L0.772762 44.6762C-1.22827 48.0088 1.17218 52.2501 5.05938 52.2501H54.9407C58.8279 52.2501 61.2284 48.0088 59.2274 44.6762L34.2867 3.13921ZM44.7224 42L30 18L15.2776 42H44.7224Z"
				fill="#BB3D3D"
			/>
		</svg>
	);
}
