import { createClient } from "@supabase/supabase-js";

const getSupabaseUrl = async () => {
	const response = await fetch("/api/supabase-url");
	const data = await response.json();
	return data.url;
};

const getSupabaseAnonKey = async () => {
	const response = await fetch("/api/supabase-anon-key");
	const data = await response.json();
	return data.key;
};

const supabaseUrl = await getSupabaseUrl();
const supabaseAnonKey = await getSupabaseAnonKey();

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
	auth: {
		autoRefreshToken: true,
		persistSession: true,
		detectSessionInUrl: false
	}
});
