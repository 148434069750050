import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import userReducer from "./reducers/user-reducer";

const reducer = {
	user: userReducer
};

const store = configureStore({
	reducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
});

export default store;
