export default function RightArrowIcon({ size = 24 }) {
	const height = size;
	const width = (size * 53) / 60;
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 53 60" fill="none">
			<path
				d="M49.8609 34.2866C53.0957 32.3443 53.0957 27.6557 49.8609 25.7134L8.32388 0.7727C4.99129 -1.22834 0.75 1.17212 0.75 5.05932L0.75 54.9407C0.75 58.8279 4.99128 61.2283 8.32388 59.2273L49.8609 34.2866Z"
				fill="#BB3D3D"
			/>
		</svg>
	);
}
